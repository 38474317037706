import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {Navigate} from 'react-router-dom';
import {FormattedMessage, useIntl} from 'react-intl';

import {useGetTranslations} from 'utils/hooks/useGetTranslations';
import {proUser} from 'utils/constants/constants';
import {IStore} from 'redux/interface';
import {getMaterialsContent, getTranslations, materialsAsyncActions, sendMaterials} from 'redux/pageAsyncActions';
import {removeMaterialAction} from 'redux/content/contentAsyncActions';
import {IArticleCard} from 'redux/content/interfaces';
import {TranslationKeys} from 'utils/constants/routeConstants';

import {ReactComponent as AppleIcon} from 'assets/image/materials/apple.svg';
import CrossroadImg from 'assets/image/library/skill-crossroad.svg';
import CrisisTextLineImg from 'assets/image/library/crisis-text-line.jpeg';
import SuccessImg from 'assets/image/materials/success.png';

import CardSection from 'components/card-section';
import Loader from 'components/loader';
import NotFoundInfo from 'components/not-found-info';
import {IShareMaterials} from 'components/materials-share/MaterialsShare';
import MaterialsShare from 'components/materials-share';

import './styles.scss';

export const Materials: React.FunctionComponent = () => {
  const {
    auth: {auth0User},
    content: {
      materialsContent,
      sendMaterialsStatus
    },
    status: {
      loading
    }
  } = useSelector((state: IStore) => state);
  const dispatch = useDispatch();
  const history = useNavigate();
  const intl = useIntl();
  const [isLoadCards, setIsLoadCards] = useState(true);
  const [isShareMode, setIsShareMode] = useState(false);
  const [checkedCards, setCheckedCards] = useState<number[]>([]);
  const [recipientInfo, setRecipientInfo] = useState<IShareMaterials>({
    emails: '',
    templateId: 0,
    toSelf: false
  });

  useGetTranslations({
    translationKey: TranslationKeys.Materials
  });

  useEffect(() => {
    dispatch(materialsAsyncActions[getMaterialsContent]());
    setIsLoadCards(false);
  }, []);

  useEffect(() => {
    setCheckedCards([]);
    setIsShareMode(false);
  }, [sendMaterialsStatus]);

  useEffect(() => {
    if (!isShareMode) {
      setCheckedCards([]);
      setRecipientInfo({
        emails: '',
        templateId: 0,
        toSelf: false
      });
    }
  }, [isShareMode]);
  if (!auth0User?.['https://gritx.org/roles'].includes(proUser)) {
    return <Navigate to="*"/>;
  }

  function removeAll() {
    const cardsId: number[] = [];

    materialsContent.map((card: IArticleCard) => {
      return cardsId.push(card.id);
    });
    dispatch(removeMaterialAction(cardsId, true));
  }

  function validateModel(): boolean {
    return Boolean(checkedCards.length && recipientInfo.emails && recipientInfo.templateId);
  }

  function handleSendMaterials() {
    if (validateModel()) {
      dispatch(materialsAsyncActions[sendMaterials]({
        contentIds: checkedCards,
        emails: recipientInfo.emails,
        templateId: recipientInfo.templateId as number,
        toSelf: recipientInfo.toSelf
      }));
    }
  }

  function handleChangeShareMode(mode: boolean) {
    setIsShareMode(mode);
  }

  function handleChangeShareMaterials(materialsModel: IShareMaterials) {
    setRecipientInfo(materialsModel);
  }

  function handleOpenCard(cardId: number) {
    if (isShareMode) {
      if (checkedCards.includes(cardId)) {
        setCheckedCards(checkedCards.filter(item => item !== cardId));
      } else {
        setCheckedCards([...checkedCards, cardId]);
      }
    } else {
      history(`/content#${cardId}`);
      // history.push(
      //   `/content#${cardId}`,
      //   history.location.state ? history.location.state : {parentLocation: history.location.pathname}
      // );
    }
  }

  function renderMaterialsCards() {
    return materialsContent.length === 0
      ? <>
        <NotFoundInfo
          title={intl.formatMessage({
            id: 'gritx.materials.notFound',
            defaultMessage: 'No Materials Added Yet'
          })}
          description={intl.formatMessage({
            id: 'gritx.materials.tapSave',
            defaultMessage: 'Tap "Add to materials" button when you view the article to save your materials here'
          })}
          image={CrisisTextLineImg}
        />
      </>
      : <>
        <CardSection
          cards={isShareMode ? materialsContent.filter(item => item.material) : materialsContent}
          disabledButtons={isShareMode}
          checkedCards={checkedCards}
          onClickCard={handleOpenCard}
        />
      </>;
  }

  return <div className="materials container">
    {
      !loading.includes(getTranslations) && <>
        {loading.includes(sendMaterials) && <Loader nested/>}
        <h1 className="materials__title"><AppleIcon className="materials__icon"/>
          <FormattedMessage
            id="gritx.materials.title"
            defaultMessage="Materials"
          />
        </h1>
        <div className="materials__share-line">
          <MaterialsShare
            isDisabledRemoveAll={!materialsContent.some(card => card.material)}
            isDisabledShare={!materialsContent.some(card => card.material)}
            isDisabledSend={!validateModel()}
            onSend={handleSendMaterials}
            onChange={handleChangeShareMaterials}
            onRemoveAll={removeAll}
            onShareMode={handleChangeShareMode}
          />

        </div>
        {
          sendMaterialsStatus
            ? <div className="materials__done">
              <div className="materials__image-wrapper">
                <img className="materials__image" src={SuccessImg} alt="send success"/>
              </div>
              <p className="materials__done-text">
                <FormattedMessage
                  id="gritx.materials.success"
                  defaultMessage="Your materials were successfully sent!"
                />
              </p>
            </div>
            : <div className="materials__cards">
              {
                loading.includes(getMaterialsContent) || isLoadCards ? <Loader nested/> : renderMaterialsCards()}
            </div>
        }
      </>
    }
  </div>;
};
