import React from 'react';
import {useIntl} from 'react-intl';

import {useGetTranslations} from 'utils/hooks/useGetTranslations';
import {TranslationKeys} from 'utils/constants/routeConstants';
import CrossroadImg from 'assets/image/library/skill-crossroad.svg';
import CrisisTextLineImg from 'assets/image/library/crisis-text-line.jpeg';
import NotFoundInfo from 'components/not-found-info';
import './styles.scss';

export const Error: React.FunctionComponent = () => {
  const intl = useIntl();

  useGetTranslations({
    translationKey: TranslationKeys.Error
  });

  return <div className={'error-page'}>
    <NotFoundInfo
      title={intl.formatMessage({
        id: 'gritx.error.notFound',
        defaultMessage: 'Sorry, we can’t find the page you were looking for.'
      })}
      image={CrisisTextLineImg}/>
  </div>;
};
