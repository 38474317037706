import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {useNavigate} from 'react-router-dom';
import {FormattedMessage, useIntl} from 'react-intl';

import {useGetTranslations} from 'utils/hooks/useGetTranslations';
import {TranslationKeys} from 'utils/constants/routeConstants';
import {IStore} from 'redux/interface';
import {getBackpackContent, backpackAsyncActions, getTranslations} from 'redux/pageAsyncActions';

import {ReactComponent as BackpackImg} from 'assets/image/backpack/backpack.svg';
import CrossroadImg from 'assets/image/library/skill-crossroad.svg';
import CrisisTextLineImg from 'assets/image/library/crisis-text-line.jpeg';

import CardSection from 'components/card-section';
import Loader from 'components/loader';
import NotFoundInfo from 'components/not-found-info';

import './styles.scss';

export const Backpack: React.FunctionComponent = () => {
  const {
    content: {
      backpackContent
    },
    status: {
      loading
    }
  } = useSelector((state: IStore) => state);
  const dispatch = useDispatch();
  const history = useNavigate();
  const intl = useIntl();
  const [isLoadCards, setIsLoadCards] = useState(true);

  useGetTranslations({
    translationKey: TranslationKeys.Backpack
  });

  useEffect(() => {
    dispatch(backpackAsyncActions[getBackpackContent]());
    setIsLoadCards(false);
  }, []);

  function handleOpenCard(cardId: number) {
    history(`/content#${cardId}`);
    // history.push(
    //   `/content#${cardId}`,
    //   history.location.state ? history.location.state : {parentLocation: history.location.pathname}
    // );
  }

  function renderFavorites() {
    return backpackContent.length === 0
      ? <>
        <NotFoundInfo
          title={intl.formatMessage({
            id: 'gritx.backpack.noFavorites',
            defaultMessage: 'No Favorites Yet'
          })}
          description={intl.formatMessage({
            id: 'gritx.backpack.noFavorites.help',
            defaultMessage: 'Tap the heart icon when you view the article to add your favorites here'
          })}
          image={CrisisTextLineImg}
        />
      </>
      : <CardSection cards={backpackContent} onClickCard={handleOpenCard}/>;
  }

  return <div className={'backpack container'}>
    {
      !loading.includes(getTranslations) && <>
        <h1 className="backpack__title"><BackpackImg className="backpack__icon"/>
          <FormattedMessage
            id={'gritx.backpack.title'}
            defaultMessage={'Backpack'}
          />
        </h1>
        <div className="backpack__cards">
          {
            loading.includes(getBackpackContent) || isLoadCards ? <Loader nested/> : renderFavorites()}
        </div>
      </>
    }

  </div>;
};
