import React from 'react';
import {FormattedMessage} from 'react-intl';

import CryingPicture from 'assets/image/contact-line/helpCry.svg';
import CrisisLifeLine from 'assets/image/contact-line/crisis_life_line-1.jpeg';
import ShareYourStory from 'assets/image/contact-line/share_your_story.jpeg';
import RunningPicture from 'assets/image/contact-line/helpRun.svg';
import TreePicture from 'assets/image/contact-line/helpTree.svg';

import './styles.scss';

type Props = {
  hideSeparator?: boolean;
}

export const ContactLine = ({hideSeparator}: Props): JSX.Element => {
  const separator = hideSeparator ? null : (<div className="contact-line__border"/>);

  return <div className="contact-line">
    {separator}
    <div className="container">
      <div className="contact-line__info">
        <img src={CrisisLifeLine} className="contact-line__info__cry-picture" alt="" role="presentation"/>
        <div className="contact-line__info__numbers">
          <div className="contact-line__info__numbers__life-line">
            <div className="contact-line__info__text">
              <b className="contact-line__info__numbers__title">
                <FormattedMessage
                  id={'gritx.footer.contact.call'}
                  defaultMessage={'Call'}
                />
              </b>
              <FormattedMessage
                id={'gritx.footer.contact.emergency'}
                defaultMessage={'Emergency telephone number:'}
              />
            </div>
            <div className="contact-line__info__numbers__number">
              {'911'}
            </div>
          </div>
          <div className="contact-line__info__numbers__life-line">
            <div className="contact-line__info__text">
              <b className="contact-line__info__numbers__title">
                <FormattedMessage
                  id={'gritx.footer.contact.call'}
                  defaultMessage={'Call'}
                />
              </b>
              <FormattedMessage
                id={'gritx.footer.contact.lifeline'}
                defaultMessage={'Emergency telephone number:'}
              />
            </div>
            <div className="contact-line__info__numbers__number">
              {'988'}
            </div>
          </div>
        </div>
        <img src={ShareYourStory} className="contact-line__info__cry-picture" alt="" role="presentation"/>
        {/* <section className="contact-line__info__pictures">*/}
        {/*   /!*<img src={RunningPicture} className="contact-line__info__pictures__run" alt="" role="presentation"/>*!/*/}
        {/*  /!* <img src={TreePicture} className="contact-line__info__pictures__tree" alt="" role="presentation"/>*!/*/}
        {/* </section>*/}
      </div>
      <div className="contact-line__info__disclaimer">
        <div className="contact-line__info__disclaimer-text">
          JUN™ is meant to serve as a tool to help with decision-making regarding managing health
          and is solely for educational purposes. JUN™ does not take the place of a healthcare provider nor
          qualify as medical opinion. Do not use JUN™ during emergencies nor to diagnose.
          For all medical emergencies, please call 911 or go to the nearest hospital.
          All information provided is safe and anonymous.
        </div>
      </div>
    </div>
    {/* <div className="contact-line__title">*/}
    {/*  <div className="container container__wrapper">*/}
    {/*    <div className="contact-line__title__text">*/}
    {/*      <FormattedMessage*/}
    {/*        id={'gritx.footer.contact.title'}*/}
    {/*        defaultMessage={'CONTACT US'}*/}
    {/*      />*/}
    {/*    </div>*/}
    {/*  </div>*/}
    {/* </div>*/}
  </div>;
};
